import React from "react";
import ContentSection from "../components/common/content-section";
import WhatColumn from "../components/what-column";
import PageTagging from "components/common/page-tagging/page-tagging";

export const publicServicesPages = [
  {
    pageName: "what-we-do",

    content: (
      <React.Fragment>
        <PageTagging
          title="Our Services | Software Development, System Integration, Productivity Tools"
          description="There is more to software development. Via a partnering approach, we create your Custom Software, System Integration &amp; Productivity tools."
        />
        <ContentSection>
          <h1>What We Do</h1>
          <p className="fs-larger fw-500">
            At <span className="fw-600 color-1">Solvable</span>, we believe that
            there is more to software development than just simple coding and
            programming. We employ a partnership approach to help solve your
            business challenges through the use of innovative technology.
          </p>
          <p>
            We listen to your needs and work with you to find the right solution
            that is both effective and efficient. Through our innovative model,
            we deliver quality solutions and help identify and multiply
            opportunities to create well-rounded efficiencies within your
            business.
          </p>

          <div className="row">
            <WhatColumn
              title="Software Scope, Design&nbsp;&amp;&nbsp;Build"
              line1="Do you have an idea for an application, program or website to solve a business need or challenge?"
              line2="Talk to us about our fully managed custom software builds and implementation using a collaborative approach."
              icon="software"
              link="/project-software-scope-design-build/"
            />
            <WhatColumn
              title="Systems Integration &amp; Middleware"
              line1="Do you have systems that don&rsquo;t talk, data in different places or spend time entering the same data in multiple places?"
              line2="Talk to us about how we can help you with integration solutions for more effective data entry, reporting and business analysis."
              icon="integration"
              link="/systems-integration-and-middleware/"
            />
            <WhatColumn
              title="Productivity &amp; Automation Tools"
              line1="Do you manually manipulate and combine data in spreadsheets? Need to provide your clients with formatted reports?"
              line2="Ask us how we can build simple tools to automate those manual tasks and improve data accuracy &amp; consistency."
              icon="productivity"
              link="/productivity-and-automation-tools/"
            />
          </div>
        </ContentSection>
      </React.Fragment>
    ),
  },
  {
    pageName: "systems-integration-and-middleware",

    content: (
      <React.Fragment>
        <PageTagging
          title="System Integration &amp; Middleware | Data Management &amp; Integration"
          description="We can create automated system and data integration solutions to help streamline your business. Business efficiency with Solvable System Integration"
        />
        <ContentSection>
          <h1>
            Systems{" "}
            <span className="color-1">Integration &amp; Middleware</span>
          </h1>
          <div className="row">
            <div className="col-md-7 col-12">
              <p className="fs-larger fw-500">
                Do you have systems that don&rsquo;t talk or data in different
                places? Spend time entering the same data in multiple places or
                external systems? Need data blended for more effective reporting
                and business analysis?
              </p>
              <p>
                We can create automated solutions to improve Data Management and
                Data Integration via our Middleware tools. While each individual
                scenario is different, we can employ a range of technical
                solutions to integrate your systems, and implement your business
                logic to improve efficiencies and reduce touch points and data
                entry duplication.
              </p>
            </div>
            <div className="col-md-5 col-12">
              <img
                src="https://solvable.azureedge.net/website-assets/systems-integration-middleware.png"
                alt="Systems Integration &amp; Middleware"
              />
            </div>
            <div className="col">
              <h3>
                Tangled up with data in all places, from all directions?
                Solvable Has the Solution
              </h3>
              <p>
                Data is a company&rsquo;s most valuable asset and significant
                investments are put in place by companies to store, organise,
                and analyse this valuable resource. System integration bridges
                the gap of lack of communication and collaboration between these
                various platforms. It helps leverage existing investments to
                maximise efficiency and accuracy.
              </p>
              <p>
                A rapidly growing organisation with multiple locations
                throughout the country, with thousands of employees in different
                departments, a steady stream of clientele, and numerous in-house
                assets, all thriving and continuing to grow at the same time.
                All of these different areas will be updating new information
                every day, all while seeking out information throughout
                different areas for reference at the same time. In order to do
                this, they will require a middleware that allows the different
                systems to seamlessly bring all that information together in an
                efficient way; thus saving time and improving accuracy all
                across the board. This is what Solvable&rsquo;s Systems
                Integration can do, promoting efficiency, stability, and
                scalability.
              </p>
            </div>
          </div>
        </ContentSection>
        <ContentSection bgClass="gry">
          <div className="row">
            <div className="col-lg-7">
              <h2>All the Data, All the Access</h2>
              <p>
                For a business that has multinational operations or joint
                ventures that may require the connection of multiple platforms
                and subsystems into one, system integration can provide them
                with the solution to large amounts of data entry. The right
                process integration can simplify entering new employees into
                multiple systems. This is useful for processes such as:
              </p>
              <ul className="dot-list">
                <li>
                  <span className="fw-600">Human Resources -</span> Keeping
                  track of new applicants to various positions becomes easier
                  and new employee data can be carefully filed. Automated
                  entries can even allow employees to input their own data onto
                  forms as needed.
                </li>
                <li>
                  <span className="fw-600">Payroll and Accounting -</span>{" "}
                  Gathering employee timesheets or logs can be accessed easily,
                  compiled, and computed for accurate payroll. Client fees and
                  payment information can be integrated into the client&rsquo;s
                  file, with itemized receipts and inventories for reference.
                </li>
                <li>
                  <span className="fw-600">Training Systems -</span> Creating
                  each individual trainee&rsquo;s file, information, and
                  progress throughout the training program can be compiled
                  together. System integration allows trainers to keep track of
                  progress, class schedules and assessments, and even allow
                  trainees to access this information for themselves.
                </li>
                <li>
                  <span className="fw-600">Warehouse Inventory -</span> Instead
                  of repeatedly entering batch codes, item codes and more, this
                  data can be organised together for easy reference. With the
                  right middleware, purchasing teams can keep track of stock
                  levels, sales teams can check on how much they still have to
                  sell, and when new items are coming.
                </li>
              </ul>
              <p>
                These are just some of the ways that data integration can be an
                invaluable tool for all-around data access. In addition to easy
                access to so much information across the different platforms,
                system integration can also automatically make backups and
                copies of the data. All the company&rsquo;s information stays up
                to date, all the way down to redundancy in case of data loss.
              </p>
            </div>
            <div className="col-lg-5 mb-3">
              <img
                src="https://solvable.azureedge.net/website-assets/business-efficiency-jigsaw.jpg"
                alt="Business efficiency with Solvable System Integration"
              />
            </div>
          </div>
        </ContentSection>
      </React.Fragment>
    ),
  },
  {
    pageName: "productivity-and-automation-tools",

    content: (
      <React.Fragment>
        <PageTagging
          title="Productivity &amp; Automation Tools | Data Accuracy | Business efficiency"
          description="We engineer automation programs to improve data accuracy and business efficiency"
        />
        <ContentSection>
          <h1>
            Productivity &amp; <span className="color-1">Automation Tools</span>
          </h1>
          <div className="row">
            <div className="col-md-7 col-12">
              <p className="fs-larger fw-500">
                Do you manually manipulate and combine data in spreadsheets?
                Need to provide your clients with formatted reports? Bulk update
                data in systems one record at a time?
              </p>
              <p>
                Automate those manual inefficient processes you and your team
                undertake. We can help create efficiency and client value by
                systemising manual processes, enabling more time to be spent on
                other valuable business tasks.
              </p>
            </div>
            <div className="col-md-5 col-12">
              <img
                src="https://solvable.azureedge.net/website-assets/productivity-and-automation-tools.png"
                alt="Systems Integration &amp; Middleware"
              />
            </div>
          </div>
        </ContentSection>
        <ContentSection bgClass="gry">
          <div className="row">
            <div className="col-lg-7">
              <h2>
                Losing time doing vital yet menial tasks? Let the masters of
                efficiency take the wheel.
              </h2>
              <p>
                Do you feel like your workflows are drowning you in Excel files?
                Are you struggling to swim to the surface when trying to make
                sense of your data? Start breathing easy with Solvable&rsquo;s
                Productivity &amp; Automation Tools. With a wealth of experience
                in digital business transformation, Solvable designs solutions
                to help you maneuver the fast-changing business landscape.
              </p>
            </div>
            <div className="col-lg-5 mb-3">
              <img
                src="https://solvable.azureedge.net/website-assets/productivity-tools-infographic.jpg"
                alt="Business efficency tools to improve your business"
              />
            </div>
          </div>
          <h3>Simplified workflows to make you more agile.</h3>
          <p>
            We engineer automation programs aligned with your objectives to help
            you let go of efficiency and productivity pain points that weigh you
            down. Furthermore, automation systematically reduces costs whilen
            improving employee performance and output. Work smarter and more
            efficiently through Solvable&rsquo;s solutions that offer scale and
            flexibility through:
          </p>
          <ul className="dot-list">
            <li>
              Streamlining enrolment processes, service delivery, and feedback
              collection.
            </li>
            <li>
              Designing workflows that will eliminate time-consuming steps and
              measuring its successes.
            </li>
            <li>
              Managing staff database, salary records, and analytical reports on
              a single platform.
            </li>
            <li>
              Refocus your resources and energies on core business functions
              like sales and marketing.
            </li>
            <li>
              Modernise the end-to-end processes such as production and
              distribution methods.
            </li>
            <li>
              Collaborate and increase productivity with tools that enable you
              to communicate seamlessly and securely with your team.
            </li>
          </ul>
          <p>
            Tap into the power of technology to multiply opportunities and
            increase efficiency and productivity tailored to your business
            needs. Explore the most suitable solutions and kickstart
            transformation at your fingertips.
          </p>
        </ContentSection>
      </React.Fragment>
    ),
  },
  {
    pageName: "project-software-scope-design-build",
    title: "",
    description: "",
    content: (
      <React.Fragment>
        <PageTagging
          title="Custom Software Development | Melbourne, Brisbane, Sydney | Solvable"
          description="We provide end-to-end custom software development where visual appeal meets functionality utilising both the latest technology &amp; innovative thinking."
        />
        <ContentSection>
          <h1>
            Custom Software <span className="color-1">Development</span>
          </h1>
          <h3>Scope, Design, Build</h3>
          <div className="row">
            <div className="col-md-7 col-12">
              <p className="fs-larger fw-500">
                Do you have an idea for an application, program or website to
                solve a business need? Talk to us about our fully managed custom
                software builds using our collaborative approach.
              </p>
              <p>
                We can assist with all stages of the build process; scoping,
                solution design through to development and launch. If you
                already have the scope, great, we can work with that too.
              </p>
              <p>
                {" "}
                <span className="fw-600">
                  Making Every Custom Software Development Solvable
                </span>
              </p>
              <p>
                The founders of Solvable believe that “Anything is Possible,
                Everything is Solvable.” It is our mission to provide quality
                end-to-end custom software development services that will
                surpass your expectations. With our extensive experience in both
                technical and business management, we simply know how to make it
                happen.
              </p>
            </div>
            <div className="col-md-5 col-12">
              <img
                src="https://solvable.azureedge.net/website-assets/project-software-scope-build.png"
                alt="Software Scope, Design, Build"
              />
            </div>
          </div>
          <h3>
            Looking to transform your imagination to a fully-functional product?
          </h3>
          <h3>Solvable can solve that!</h3>
          <p>
            Solvable provides end-to-end custom software development where
            visual appeal meets functionality. We use both the latest technology
            and innovative thinking to create systems and designs that are
            user-friendly and tailored to fit your business needs.
          </p>
          <p>
            Through strategic planning and conceptualisation, we turn your ideas
            to solutions that work. We pride ourselves in delivering exceptional
            software that ticks all the boxes in our quality checklist:
          </p>
          <ul className="dot-list">
            <li>
              <span className="fw-600">Accuracy -</span> Our team makes sure
              that the project meets all the requirements provided by the
              client.
            </li>
            <li>
              <span className="fw-600">Usability - </span>The output is easy to
              use, even for non-tech-savvy users.
            </li>
            <li>
              <span className="fw-600">Reliability - </span>The software does
              not fail during execution and will work even at the busiest times.
            </li>
            <li>
              <span className="fw-600">Security - </span>We ensure that best
              practice data security and system integrity are always at the
              forefront of our development strategy.
            </li>
            <li>
              <span className="fw-600">Efficiency - </span>The software will
              operate efficiently and perform the desired functions everytime.
            </li>
          </ul>
        </ContentSection>
      </React.Fragment>
    ),
  },
];
