import React from "react";
import WhatColumn from "../../../../components/what-column";

const WhatSolvable = () => {
  return (
    <section id="what" className="what p100">
      <div className="fluid-container fixed-width-fluid">
        <div className="title">
          <div className="slvicon slvicon-why"></div>
          <h2 className="upper text-center">
            What <span className="color-1">we do</span>
          </h2>
          <p className="fs-larger fw-500">
            At Solvable, we believe that there is more to software development
            than just simple coding and programming. We employ a partnership
            approach to help solve your business challenges through the use of
            innovative technology.
          </p>
          <p>
            We listen to your needs and work with you to find the right solution
            that is both effective and efficient. Through our innovative model,
            we deliver quality solutions and help identify and multiply
            opportunities to create well-rounded efficiencies within your
            business.
          </p>
        </div>
        <div className="row">
          <WhatColumn
            title="Software Scope, Design&nbsp;&amp;&nbsp;Build"
            line1="Do you have an idea for an application, program or website to solve a business need or challenge?"
            line2="Talk to us about our fully managed custom software builds and implementation using a collaborative approach."
            icon="software"
            link="/project-software-scope-design-build/"
          />
          <WhatColumn
            title="Systems Integration &amp; Middleware"
            line1="Do you have systems that don&rsquo;t talk, data in different places or spend time entering the same data in multiple places?"
            line2="Talk to us about how we can help you with integration solutions for more effective data entry, reporting and business analysis."
            icon="integration"
            link="/systems-integration-and-middleware/"
          />
          <WhatColumn
            title="Productivity &amp; Automation Tools"
            line1="Do you manually manipulate and combine data in spreadsheets? Need to provide your clients with formatted reports?"
            line2="Ask us how we can build simple tools to automate those manual tasks and improve data accuracy &amp; consistency."
            icon="productivity"
            link="/productivity-and-automation-tools/"
          />
        </div>
      </div>
    </section>
  );
};

export default WhatSolvable;
