import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NotificationSystem from "react-notification-system";
import ScrollToTop from "./components/common/scroll-to-top";
import NavBar from "./components/nav-bar";
import Footer from "./components/footer";
import Home from "./views/home";
import CapabilityStatement from "./views/capability-statement";
import ContactUs from "./views/contact-us";
import ContentPage from "./views/content-page";
import { navLinks } from "./constants/navigation";
import "./App.scss";

function App() {
  const notificationSystem = React.createRef();

  const addNotification = (message, level = "success") => {
    const notification = notificationSystem.current;
    notification.addNotification({
      message: message,
      level: level,
      position: "tr",
      autoDismiss: 10
    });
  };

  const notificationStyle = {
    NotificationItem: {
      // Override the notification item
      DefaultStyle: {
        // Applied to every notification, regardless of the notification level
        margin: "10px 5px 2px 1px",
        padding: "15px",
        borderRadius: "4px",
        fontSize: "1.1em",
        color: "white",
        backgroundColor: "#313131",
        width: "500px",
        maxWidth: "100%"
      },
      success: {
        // Applied only to the success notification item
        borderTop: "2px solid Green"
      },
      error: {
        // Applied only to the error notification item
        borderTop: "2px solid Red"
      }
    },
    Dismiss: {
      DefaultStyle: {
        color: "white",
        backgroundColor: "#313131"
      }
    }
  };

  return (
    <Router>
      <ScrollToTop>
        <div className="solvable-app">
          <NavBar navLinks={navLinks} />
          <main role="main">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route
                exact
                path="/capability-statement/"
                component={CapabilityStatement}
              />
              <Route
                exact
                path="/get-in-touch/"
                render={props => (
                  <ContactUs addNotification={addNotification} {...props} />
                )}
              />
              <Route exact path="*" component={ContentPage} />
            </Switch>
          </main>
          <Footer />
          <NotificationSystem
            ref={notificationSystem}
            style={notificationStyle}
          />
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
