import React from "react";
import PageTagging from "components/common/page-tagging/page-tagging";
import ContactUsForm from "../../components/contact-us-form";

const ContactUs = ({ addNotification }) => {
  return (
    <React.Fragment>
      <PageTagging
        title="Solvable | Get in Touch | 1300 732 433"
        description="Contact us now for your custom software solutions. Call 1300 732 433"
      />
      <div className="h-100 gradient">
        <ContactUsForm addNotification={addNotification} />
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
