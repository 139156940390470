import React from "react";
import { Link } from "react-router-dom";
import "./hero.scss";

const Hero = () => {
  return (
    <section className="hero h-100">
      <div className="overlay grey-gradient"></div>
      <div className="fluid-container fixed-width-fluid">
        <div className="box py-2">
          <div className="row">
            <div className="col-lg-7 col-md-8 col-12">
              <h1>
                Anything is Possible.
                <br />
                Everything is Solvable.
              </h1>
              <h3 className="my-4">Software Development, Done differently.</h3>
              <p>
                Our vision is to help businesses improve efficiency and client
                value by crafting the perfect blend of technology solutions and
                efficient business strategies.
              </p>
              <br />
              <button
                onClick={() => document.getElementById("why").scrollIntoView()}
                className="btn learn scroll-btn color-1-hv"
              >
                learn more &nbsp;<i className="fas fa-angle-right"></i>
              </button>
              <Link
                to="/get-in-touch/"
                className="btn touch scroll-btn color-1-hv hidden-xs"
              >
                <i className="fas fa-envelope"></i> get in touch
              </Link>
            </div>
            <div className="col-lg-5 col-md-4 col-12">
              <div className="box">
                <img
                  src="https://solvable.azureedge.net/website-assets/everything-is-solvable-hero.png"
                  alt="Anything is Possible, Everything is Solvable."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
