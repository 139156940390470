import React from "react";
import { Link } from "react-router-dom";
import "./what-column.scss";

const WhatColumn = ({ title, line1, line2, icon, link }) => {
  return (
    <div className="col-lg-4 col-sm-6 col-12 mt-5">
      <div className="box text-center what-col">
        <div>
          <div className={`slvicon slvicon-${icon}`}></div>
          <h3>{title}</h3>
          <p>{line1}</p>
          <p>{line2}</p>
        </div>
        <div>
          <Link to={link} className="btn learn color-1-bg color-2-bg-hv">
            Learn More <i className="fas fa-angle-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WhatColumn;
