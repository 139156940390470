import React from "react";
import "./our-highlights.scss";

const OurHighlights = ({ highlights }) => {
  const getHighlights = highlights => {
    let rows = [];

    let a = highlights;
    let count = 0;

    while (a.length) {
      let b = a.splice(0, 2);
      count++;

      rows.push(
        <div className="row">
          <div key={count} className="col-sm-6 col-12 p-3 highlight">
            <span className="title">{b[0].title}</span>
            <span className="details">{b[0].details}</span>
          </div>

          <div key={count + 1} className="col-sm-6 col-12 p-3 highlight">
            <span className="title">{b[1].title}</span>
            <span className="details">{b[1].details}</span>
          </div>
        </div>
      );
      count++;
    }

    return rows;
  };

  return <div className="our-highlights">{getHighlights(highlights)}</div>;
};

export default OurHighlights;
