import React, { Component } from "react";
import PageTagging from "components/common/page-tagging/page-tagging";
import ContactUsForm from "../../components/contact-us-form";
import Hero from "./components/hero";
import WhatSolvable from "./components/what-solvable";
import WhySolvable from "./components/why-solvable";
import "./home.scss";
import ContentSection from "components/common/content-section";
import { Link } from "react-router-dom";

class Home extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <PageTagging
          title="Software Development, System Integration &amp; Productivity Tools | Solvable"
          description="There is more to software development. Via a partnering approach, we create your Custom Software, System Integration &amp; Productivity tools."
        />
        <Hero />
        <WhatSolvable />
        <WhySolvable />
        <ContentSection>
          <div className="title">
            <div className="slvicon slvicon-why"></div>
            <h2 className="upper text-center">
              Our <span className="color-1">Products</span>
            </h2>
          </div>
          <div className="row text-center">
            <div className="col-md-6 col-12">
              <p>
                <Link to="/products/">
                  <img
                    src="https://solvable.azureedge.net/website-assets/staffd-logo-stacked-v1.png"
                    alt=""
                  />
                </Link>
              </p>
              <p>
                <span className="fw-600 font-italic">Staffd</span> is a Web
                Based Applicant Management, Employee onboarding and eLearning
                system designed to streamline your hiring processes and add
                automation. Take away the paper processes and add efficiency.
              </p>

              <Link
                to="/products/"
                className="btn learn color-1-bg color-2-bg-hv my-1"
              >
                Learn More <i className="fas fa-angle-right"></i>
              </Link>
            </div>
            <div className="col-md-6 col-12">
              <p>
                <Link to="/products/">
                  <img
                    src="https://solvable.azureedge.net/website-assets/actiond-stacked-logo-v3.png"
                    alt="Actiond - Safety and Compliance made easy"
                  />
                </Link>
              </p>
              <p>
                <span className="fw-600 font-italic">Actiond</span> is a safety
                and compliance system that allows your business to enjoy
                continuous improvement by sharing information so you can work
                smarter and engage with your people and your clients to optimise
                your objectives.
              </p>
              <Link
                to="/products/"
                className="btn learn color-1-bg color-2-bg-hv my-1"
              >
                Learn More <i className="fas fa-angle-right"></i>
              </Link>
            </div>
          </div>
        </ContentSection>
        <section className="contact p100" id="contact">
          <div className="overlay gradient"></div>
          <ContactUsForm />
        </section>
      </React.Fragment>
    );
  }
}

export default Home;
