import React from "react";
import { Row, Col } from "reactstrap";
import ActiondModuleHeading from "../components/actiond-module-heading";
import ContentSection from "../components/common/content-section";
import { publicServicesPages } from "./public-services-content";
import PageTagging from "components/common/page-tagging/page-tagging";
import { Link } from "react-router-dom";

export const publicPages = [
  {
    pageName: "about",

    content: (
      <React.Fragment>
        <PageTagging
          title="About Solvable | Custom Software Development | System Integration"
          description="We take systems integration, software development, and application development to the next level. How? With the help of our clients."
        />
        <ContentSection>
          <h1>
            About <span className="color-1">Solvable</span>
          </h1>
          <h3>Software Development, Done Differently</h3>
          <div className="row mt-4">
            <div className="col-md-8">
              <h2>The Story Behind &ldquo;Solvable&rdquo;</h2>
              <p className="fs-larger fw-500">
                The Solvable founders noticed that there was a fundamental piece
                missing in today&rsquo;s software development: strategic
                business planning.
              </p>
              <p>
                After working together for over 10 years in other businesses,
                founders Trevor and Adam decided to join forces and form a
                unique working style: putting business needs first, then
                tailoring a technology that would best fit that acumen.
              </p>
              <p>
                After assessing the services we aim to deliver to our clients,
                the name for the company was crystal clear. We want to solve
                problems, provide a solution and the name{" "}
                <span className="fw-600 color-1">Solvable</span> was a natural
                fit. Everything is Solvable is more than a name, it is our
                philosophy.
              </p>
            </div>
            <div className="col-md-4">
              <img
                src="https://solvable.azureedge.net/website-assets/about-solvable.png"
                className="Software Development, Done Differently"
                alt="About"
              />
            </div>
          </div>
          <h2 className="mt-3">More than a Service Provider</h2>
          <p className="fs-larger fw-500">
            Our goal is simple yet impactful: to employ a partnership approach
            that will help you solve your problems and to challenge the
            ordinary. We take systems integration, software development, and
            application development to the next level. How? With the help of our
            clients.
          </p>
          <p>
            At Solvable, we value our client&rsquo;s ideas and areas of concern
            to create a customised project that will help their businesses in
            the best way possible, technically and business-wise. No matter
            where you are in the world, our team will be there to the rescue! We
            are more than your service providers, we are your partners!
          </p>
          <a name="our-founders" />
        </ContentSection>
        <ContentSection bgClass="darkgry" padding={3}>
          <h2 className="my-0">
            Our <span className="color-1">Founders</span>
          </h2>
        </ContentSection>
        <ContentSection bgClass="gry" padding={4}>
          <h3 className="color-1">Adam Luck</h3>
          <span className="fw-600">
            Technology Leader | Solution Architect | Senior .NET Developer
          </span>
          <div className="row">
            <div className="col-md-8">
              <p>
                With an &lsquo;anything is possible&rsquo; attitude, and as an
                experienced solution architect and senior .NET full stack
                developer, Adam enjoys taking a collaborative approach to
                deliver the most effective and efficient solutions for his
                clients. By asking the right questions, and drawing upon his
                extensive expertise in designing operational and back office
                systems, he is able to rapidly conceptualise the scope of a
                project, and quickly ascertain any challenges that may be
                encountered along the way.
              </p>
              <p>
                Adam has experience designing enterprise level, business
                critical systems across a variety of industries, ranging from
                ERP, field management and paperless solutions, through to
                finance, CRM and other complex integrations.
              </p>
              <p>
                He prides himself on his attention to detail, and ability to
                translate technical information into plain language, and will
                regularly check in with his clients to ensure that their
                expectations are being exceeded.
              </p>
              <p>
                <span className="fw-600">Areas of Expertise:</span>
              </p>
              <ul className="dot-list">
                <li>
                  Strong technical background with over 17 years&rsquo;
                  experience
                </li>
                <li>
                  Technical Leadership: solutions architecture, business, vision
                  &amp; implementation
                </li>
                <li>Design &amp; development of market leading solutions</li>
                <li>Agile methodologies &amp; Lean project management</li>
                <li>Emerging technology evangelist</li>
              </ul>
              <p>
                <a
                  href="https://www.linkedin.com/in/adam-s-luck"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://solvable.azureedge.net/website-assets/linkedin-logo.png"
                    alt="LinkedIn"
                    className="linkedin"
                  />
                </a>
              </p>
            </div>
            <div className="col-md-4">
              <img
                src="https://solvable.azureedge.net/website-assets/adam-luck.png"
                className="profile-image"
                alt="Adam Luck"
              />
            </div>
          </div>
        </ContentSection>
        <ContentSection padding={4}>
          <h3 className="color-1">Trevor McBay</h3>
          <span className="fw-600">
            Business Transformation Leader | Change Management | ICT Consulting
          </span>
          <div className="row">
            <div className="col-md-8">
              <p>
                Trevor is an extensively experienced business leader with a
                focus on systems and process improvements, technology, logistics
                and procurement. He is highly motivated and organised, having
                led large operational and technical project teams across
                Australia, USA and Asia, he brings a wide range of management
                and leadership skills to the business.
              </p>
              <p>
                With over 20 years&rsquo; experience in senior executive teams
                and strategic direction committees, Trevor assisted in leading
                business growth and efficiency employing his problem solving and
                innovation lead approach.
              </p>
              <p>
                Trevor is values lead and solutions driven and has a passion for
                solving problems. He utilises his years of strategic leadership
                experience to create value and effectiveness.
              </p>
              <p>
                <span className="fw-600">Areas of Expertise:</span>
              </p>
              <ul className="dot-list">
                <li>Strategic leadership and strong partnership approach</li>
                <li>
                  Extensive experience in offshoring and building large teams
                </li>
                <li>Skilled in software solution design and implementation</li>
                <li>Builds strategic supplier procurement relationships</li>
                <li>
                  Strong in documented policies and procedures consistent with
                  ISO standards
                </li>
              </ul>
              <p>
                <a
                  href="https://www.linkedin.com/in/trevor-mcbay-a2942610"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://solvable.azureedge.net/website-assets/linkedin-logo.png"
                    alt="LinkedIn"
                    className="linkedin"
                  />
                </a>
              </p>
            </div>
            <div className="col-md-4">
              <img
                src="https://solvable.azureedge.net/website-assets/Trevor-McBay.jpg"
                className="profile-image"
                alt="Trevor McBay"
              />
            </div>
          </div>
        </ContentSection>
      </React.Fragment>
    ),
  },
  {
    pageName: "recent-projects",

    content: (
      <React.Fragment>
        <PageTagging
          title="Recent Projects &amp; Testimonials | Custom Website Development"
          description="The team at Solvable are proud of the work we undertake and appreciate the partnering relationship we have built with our clients."
        />
        <ContentSection>
          <h1>
            Our Recent <span className="color-1">Projects</span>
          </h1>
          <p className="fs-larger fw-500">
            The team at <span className="fw-600 color-1">Solvable</span> are
            proud of the work we undertake and appreciate the partnering
            relationship we have built with our clients.
          </p>
          <p className="fs-larger fw-500">
            Here are a few examples of our recent work;
          </p>
        </ContentSection>
        <ContentSection bgClass="darkgry">
          <h2 className="text-center">
            Our <span className="color-1">Products</span>
          </h2>
          <div className="row text-center">
            <div className="col-md-6 col-12">
              <p>
                <Link to="/products/">
                  <img
                    src="https://solvable.azureedge.net/website-assets/staffd-logo-stacked-v1.png"
                    alt=""
                  />
                </Link>
              </p>
              <p>
                <span className="fw-600 font-italic">Staffd</span> is a Web
                Based Applicant Management, Employee onboarding and eLearning
                system designed to streamline your hiring processes and add
                automation. Take away the paper processes and add efficiency.
              </p>

              <Link
                to="/products/"
                className="btn learn color-1-bg color-2-bg-hv my-1"
              >
                Learn More <i className="fas fa-angle-right"></i>
              </Link>
            </div>
            <div className="col-md-6 col-12">
              <p>
                <Link to="/products/">
                  <img
                    src="https://solvable.azureedge.net/website-assets/actiond-stacked-logo-v3.png"
                    alt="Actiond - Safety and Compliance made easy"
                  />
                </Link>
              </p>
              <p>
                <span className="fw-600 font-italic">Actiond</span> is a safety
                and compliance system that allows your business to enjoy
                continuous improvement by sharing information so you can work
                smarter and engage with your people and your clients to optimise
                your objectives.
              </p>
              <Link
                to="/products/"
                className="btn learn color-1-bg color-2-bg-hv my-1"
              >
                Learn More <i className="fas fa-angle-right"></i>
              </Link>
            </div>
          </div>
        </ContentSection>

        <ContentSection bgClass="gry">
          <div className="row">
            <div className="col-md-6 col-12">
              <h3 className="color-1">CTA - Create Train Achieve</h3>
              <p>
                Another project delivered... The team at Solvable are pleased to
                announce the launch of the new Website and Student Management
                System for CTA - Create Train Achieve. This was an exciting
                project for the team and we believe a great result for all.
              </p>
              <p>
                It was a pleasure working with the team at CTA and we wish them
                all the best with their future.
              </p>
              <div className="line"></div>
              <p className="testimonial">
                &ldquo;Over the last couple of months, our marketing team in
                conjunction with Solvable Pty Ltd have been working tirelessly
                to piece together our new website and admin functions. We asked
                solvable to have a product for you to utilise in either the palm
                of your hand or on a PC, You will see some of the following
                amazing features whilst using the platform;&rdquo;
              </p>
              <ul>
                <li className="testimonial">
                  Clear information for our available courses
                </li>
                <li className="testimonial">
                  Live chat function, allowing you to ask quick questions with
                  no extra browsers
                </li>
                <li className="testimonial">
                  Mobile and Tablet friendly with a completely responsive design
                </li>
                <li className="testimonial">
                  Student admin portal that allows you to navigate your upcoming
                  and prior bookings within a few clicks
                </li>
                <li className="testimonial">
                  Enrol online meaning less annoying paperwork
                </li>
                <li className="testimonial">
                  Corporate booking portal that allows companies to book courses
                  for their staff or clients. This includes a management
                  function to track course status
                </li>
                <li className="testimonial">
                  Frequently Asked Questions page to allow users to quickly and
                  easily find answers to their queries including smart text
                  search, enabling you to find things quicker
                </li>
              </ul>
              <p className="testimonial">
                &ldquo;The team at solvable have placed many man hours into our
                product and are still continuing to support better and bigger
                platforms. We would highly recommend any new or old RTO get in
                contact with them, if you want a seamless process for your
                students, trainers and admin staff.&ldquo;
              </p>
              <p className="testimonial-name">
                Damian Paul - CEO
                <br />
                CTA
              </p>
              <p>
                <a
                  href="https://cta.edu.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn learn color-1-bg color-2-bg-hv"
                >
                  Check It Out <i className="fas fa-angle-right"></i>
                </a>
              </p>
            </div>
            <div className="col-md-6 col-12">
              <div className="mb-2">
                <a
                  href="https://cta.edu.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://solvable.azureedge.net/website-assets/cta-home3.jpg"
                    alt="Civil Training Australia"
                    className="screenshot"
                  />
                </a>
              </div>
              <div className="mb-2">
                <a
                  href="https://cta.edu.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://solvable.azureedge.net/website-assets/cta-screenshot3.jpg"
                    alt="CTA - Create Train Achieve"
                    className="screenshot"
                  />
                </a>
              </div>
            </div>
          </div>
        </ContentSection>
        <ContentSection>
          <div className="row">
            <div className="col-md-6 col-12">
              <h3 className="color-1">XerTransfer</h3>
              <p className="fs-larger fw-500">
                Solution Based Software for the project management industry
              </p>
              <p>
                XerTransfer is a Solution Based Software for the project
                management industry for users of Microsoft Project &amp; Oracle
                Primavera P6
              </p>
              <p>
                This was an exciting project for us and we are all pleased with
                the outcome. This is a great step forward and we wish the team
                at XerTransfer well into the future.
              </p>
              <div className="line"></div>
              <p className="testimonial">
                &ldquo;We extend our sincere thanks and appreciation to the
                Solvable team who not only brought our website vision to life
                but provided a level of expert guidance and service in assisting
                us to meet critical timing objectives and overcome arising
                issues with assurance and confidence in approach. Both Adam and
                Trevor understood our business, our strategic direction and our
                client base supporting us to achieve and deliver a website and
                user platform that has made considerable impact and impression
                to key clientele, provided invaluable advice, improved
                governance holistically to our environment, security,
                reportability and user management. The experience was refreshing
                to us, we now have the right management fit for our continuing
                business and endeavours giving us peace of mind, reliability,
                stability and transparency across our business platform. The
                attitude and aptitude we experienced is true to the name
                Solvable whereby our requests, needs and challenging
                circumstances were expertly managed with a resolution focused
                team of dedicated professionals.&rdquo;
              </p>
              <p className="testimonial-name">
                Sterling Gallagher
                <br />
                Director, XerTransfer
              </p>
              <p>
                <a
                  href="https://www.xertransfer.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn learn color-1-bg color-2-bg-hv"
                >
                  Check It Out <i className="fas fa-angle-right"></i>
                </a>
              </p>
            </div>
            <div className="col-md-6 col-12">
              <a
                href="https://www.xertransfer.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://solvable.azureedge.net/website-assets/xertransfer2.png"
                  alt="XerTransfer"
                  className="screenshot"
                />
              </a>
            </div>
          </div>
        </ContentSection>
        <ContentSection bgClass="gry">
          <div className="row">
            <div className="col-md-6 col-12">
              <h3 className="color-1">
                Food Safety Education by Train to Gain
              </h3>
              <p className="fs-larger fw-500">
                Train to Gain is a Nationally Accredited Registered Training
                Organisation (RTO provider # 22361) with over 30 years of
                Hospitality and Retail experience.
              </p>
              <p>
                It was a pleasure working with Nat and her team to build their
                brand new site.
              </p>
              <p>
                The site provides a simple and easy to use platform for food
                safety online learning. It incorporates a simple course
                selection process, buy online and undertake Nationally
                Accredited training for individuals and companies.
              </p>
              <div className="line"></div>
              <p className="testimonial">
                &ldquo;Solvable Pty Ltd was an easy decision to choose when we
                decided to give our online learning platform a complete
                overhaul. The expertise and creative solutions in order to make
                our website both student and staff friendly from the Solvable
                team, continues to save us many hours of administration work and
                has optimised our SEO by 20%. We appreciate the attention to
                detail and creative approach provided by the Solvable team and
                their continuing support. Solvable Pty Ltd, literally solved all
                of our website demands! Awesome work Adam and Trevor, from the
                team at Train to Gain Pty Ltd.&rdquo;
              </p>
              <p className="testimonial-name">
                Natalie Towers
                <br />
                Director, Train to Gain
              </p>
              <p>
                <a
                  href="https://www.foodsafetyeducation.com.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn learn color-1-bg color-2-bg-hv"
                >
                  Check It Out <i className="fas fa-angle-right"></i>
                </a>
              </p>
            </div>
            <div className="col-md-6 col-12">
              <a
                href="https://www.foodsafetyeducation.com.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://solvable.azureedge.net/website-assets/train-to-gain2.png"
                  alt="Food Safety Education"
                  className="screenshot"
                />
              </a>
            </div>
          </div>
        </ContentSection>
        <ContentSection>
          <div className="row">
            <div className="col-md-6 col-12">
              <h3 className="color-1">Strategically Yours</h3>
              <p className="fs-larger fw-500">
                Specialising in Executive, Business and Leadership Coaching
              </p>
              <p>
                Coaching and mentoring for the next generation of successful
                executives, business owners, senior managers and leaders,
                through tailored programs that utilise and clarify Management
                Intelligence, Emotional Intelligence and Conversational
                Intelligence&reg;.
              </p>
              <p>
                We enjoyed working on this project for Mike and building a site
                that helps represent his impressive coaching style.
              </p>
              <div className="line"></div>
              <p className="testimonial">
                &ldquo;I would like to thank the team at Solvable for building
                my new website. I congratulate their agility in meeting my
                needs, ability to design a website that more than met my
                expectations, ability to adapt to my visual needs ... yet
                ability to positively influence my thinking, guidance on the
                overall usability of the site and extremely positive feedback
                that I’ve received from clients and visitors.&rdquo;
              </p>
              <p className="testimonial-name">
                Mike Cameron
                <br />
                Strategically Yours
              </p>
              <p>
                <a
                  href="https://www.strategically.com.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn learn color-1-bg color-2-bg-hv"
                >
                  Check It Out <i className="fas fa-angle-right"></i>
                </a>
              </p>
            </div>
            <div className="col-md-6 col-12">
              <a
                href="https://www.strategically.com.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://solvable.azureedge.net/website-assets/strategically-yours3.png"
                  alt="Strategically Yours"
                  className="screenshot"
                />
              </a>
            </div>
          </div>
        </ContentSection>
        <ContentSection bgClass="gry">
          <div className="row">
            <div className="col-md-6 col-12">
              <h3 className="color-1">Mr Traffic Hire</h3>
              <p className="fs-larger fw-500">
                Mr Traffic Hire - Melbourne's leading specialised traffic
                equipment hire company.
              </p>
              <p>
                Mr Traffic Hire offers specialised traffic equipment hire on any
                scale, such as large infrastructure road works and projects to
                single Variable Messaging Signs (VMS) for advertising. We have a
                full range of traffic safety equipment.
              </p>
              <p>
                With over 25 years combined experience in the Hire industry Mr
                Traffic Hire can offer our clients the most up to date solutions
                for managing your needs, combined with our vast industry
                knowledge, makes us confident that we can provide solutions to
                our clients.
              </p>
              <p>
                It was a pleasure working with Jamie and the team at Mr Traffic
                Hire. A great operator and an impressive team.
              </p>
              <div className="line"></div>

              <p>
                <a
                  href="https://www.mrtraffichire.com.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn learn color-1-bg color-2-bg-hv"
                >
                  Check It Out <i className="fas fa-angle-right"></i>
                </a>
              </p>
            </div>
            <div className="col-md-6 col-12">
              <a
                href="https://www.mrtraffichire.com.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://solvable.azureedge.net/website-assets/mr-traffic-home.png"
                  alt="Mr Traffic Hire"
                  className="screenshot"
                />
              </a>
            </div>
          </div>
        </ContentSection>
        <ContentSection>
          <div className="row">
            <div className="col-md-7 col-12">
              <h3 className="color-1">Curtin University</h3>
              <p>
                Solvable recently completed a project creating a number of
                productivity tools to assist Curtin University with their
                research projects.
              </p>
              <p>
                It was a privilege working on such a worthwhile project. Another
                example of how automation can improve productivity and accuracy.
              </p>
              <div className="line"></div>
              <p className="testimonial">
                &rdquo;The data amalgamation programs created for us by Solvable
                have significantly increased our efficiency by reducing the
                amount of time spent on data processing. Across two programs, we
                are likely to save more than 200 person hours per year which
                frees up staff time to conduct more research. The programs are
                easy to use, reliable, and eliminate human error. They were
                tailored to our very specific requirements and the development
                process was efficient and smooth.&rdquo;
              </p>
              <p className="testimonial-name">
                Professor Ottmar Lipp
                <br />
                John Curtin Distinguished Professor
                <br />
                School of Psychology Curtin University
              </p>
            </div>
            <div className="col-md-5 col-12">
              <img
                src="https://solvable.azureedge.net/website-assets/curtin-logo.jpeg"
                alt="Curtin University"
              />
            </div>
          </div>
        </ContentSection>
      </React.Fragment>
    ),
  },
  {
    pageName: "products",

    content: (
      <React.Fragment>
        <PageTagging
          title="Our Products | Custom Software Solutions | Actiond | Staffd"
          description="Solvable custom software development and market ready products. Let Solvable assist with your business efficiency."
        />
        <ContentSection>
          <h1>
            Our <span className="color-1">Products</span>
          </h1>
          <p>
            At Solvable, we not only create custom software solutions for our
            clients, we also create innovative, market ready products to
            compliment our services. We draw on our unique mix of practical
            business experience and a solution focused development team to
            create products that improve business efficiency and support growth.
          </p>
          <h4>See below for further details on our products.</h4>
        </ContentSection>
        <ContentSection bgClass="gry">
          <div className="col-md-4 col-12">
            <p>
              <img
                src="https://solvable.azureedge.net/website-assets/staffd-logo-2-01.png"
                alt=""
              />
            </p>
          </div>
          <h4>
            <span className="color-staffd-blue">
              Simplify Your Hiring Process
            </span>
          </h4>
          <h2>Onboarding &amp; eLearning Made Easy</h2>
          <h4>
            <span className="color-staffd-green">
              Complete Employee Onboarding Online
            </span>
          </h4>
          <div className="row">
            <div className="col-lg-7">
              <p className="fs-larger fw-500">
                <span className="fw-600 font-italic color-staffd">Staffd</span>{" "}
                is a Web Based Applicant Management, Employee onboarding and
                eLearning system designed to streamline your hiring processes
                and add automation.
              </p>
              <p className="fs-larger fw-500">
                <span className="fw-600 font-italic color-staffd">Staffd</span>{" "}
                takes away all the paperwork that creates hard lengthy work when
                hiring new team members. Your applicants will complete all
                requirements online from application to qualification uploads
                and company inductions.
              </p>
              <p>Here are just a few of the key features;</p>
              <ul className="dot-list">
                <li>
                  Streamlined application processes, data collection, and
                  supporting document uploads
                </li>
                <li>Application form based on your requirements</li>
                <li>Aptitude quizzes with scoring for ease of validation</li>
                <li>Licence and qualification uploads</li>
                <li>Track and record interviews</li>
                <li>Company Policy access and agreement</li>
                <li>Internal and external induction completion</li>
                <li>Statutory form uploads (Tax and Super)</li>
                <li>Offer Letter review and digital signature</li>
                <li>Hold points for review and approval</li>
                <li>Ability for system integration (Payrol or ERP)</li>
                <li>Automated email notifications</li>
                <li>
                  Build your own engaging eLearning content with our simple
                  editor
                </li>
                <li>Include text, images and even videos</li>
                <li>
                  Create a questionnaire with scoring to ensure Competence
                </li>
                <li>
                  Track the completion status for your team with our simple team
                  view
                </li>
                <li>Add Toolbox meetings as a module</li>
                <li>And more&hellip;</li>
              </ul>
            </div>
            <div className="col-lg-5 mb-3">
              <img
                src="https://solvable.azureedge.net/website-assets/staffd-website/staffd-phone.png"
                alt="Staffd Mobile View"
                className=""
              />
            </div>
            <div className="col-lg-6 mb-3">
              <img
                src="https://solvable.azureedge.net/website-assets/staffd-website/manage-applicants-v3.png"
                alt="Licence uploads"
                className=""
              />
            </div>
            <div className="col-lg-6 mb-3">
              <img
                src="https://solvable.azureedge.net/website-assets/staffd-website/elearning-view-v3.png"
                alt="Staffd Application Form"
                className=""
              />
            </div>
          </div>
          <p>
            <a
              href="https://staffd.me/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn learn color-1-bg color-2-bg-hv my-1"
            >
              Check It Out <i className="fas fa-angle-right"></i>
            </a>{" "}
            <a
              href="https://solvable.azureedge.net/website-assets/staffd-website/staffd-brochure-2021-v2.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="btn learn color-1-bg color-2-bg-hv my-1"
            >
              Download Brochure <i className="fas fa-angle-right"></i>
            </a>
          </p>
        </ContentSection>
        <ContentSection>
          <div className="col-md-4 col-12">
            <p>
              <img
                src="https://solvable.azureedge.net/website-assets/actiond-logo.png"
                alt="Actiond - Safety and Compliance made easy"
              />
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <h4 className="color-1">Safety &amp; Compliance Made Easy</h4>
              <h4 className="color-3">Reduce Your Paperwork</h4>
              <h4 className="color-2">
                Gather More Accurate &amp; Meaningful Information
              </h4>
              <p className="fs-larger fw-500">
                (All While Improving Safety, Efficiency &amp; Meeting
                Compliance)
              </p>
              <p>
                Actiond is a software workplace platform that allows your
                business to share information so you can work smarter and engage
                with your people and your clients to optimise your objectives.
              </p>
              <p>
                Actiond achieves this with different easy-to-use modules that
                integrate with each other. There are currently 6 major modules
                that will simplify your business needs.
              </p>
            </div>
            <div className="col-lg-6 mb-3">
              <img
                src="https://solvable.azureedge.net/website-assets/actiond-main-image.jpg"
                alt="Actiond - Dashboard"
                className="screenshot"
              />
            </div>
          </div>

          <Row>
            <Col lg={7}>
              <ActiondModuleHeading title="Document Management" icon="file" />
              <ul>
                <li>
                  Give your team access to your policies, procedures and
                  documents
                </li>
                <li>ISO standard document management</li>
                <li>
                  Provides access to documents on any device based on your
                  permission controls
                </li>
                <li>
                  Ability to mark documents as controlled docs, favourite and
                  set a review date reminder
                </li>
                <li>
                  Upload Word, Excel, PDF, PowerPoint, Google doc links, images
                  and hosted videos
                </li>
              </ul>
              <ActiondModuleHeading
                title="Incident Management"
                icon="exclamation-triangle"
              />{" "}
              <ul>
                <li>
                  Close the investigation loop for all incidents to focus your
                  training efforts and comply with OHS legislation
                </li>
                <li>
                  Lodge incidents online anywhere on any device using your own
                  self managed report
                </li>
                <li>
                  Email notifications and actions created for completion by
                  investigators
                </li>
                <li>Add images and documents to an incident</li>
                <li>
                  Statistics and ratios automatically created allowing for trend
                  analysis
                </li>
                <li>Dashboard style views</li>
              </ul>
              <ActiondModuleHeading title="Auditing" icon="clipboard-check" />{" "}
              <ul>
                <li>
                  Use audit data to focus your training while maintaining
                  compliance with Road Authority standards
                </li>
                <li>Build as many of your own audit templates as required</li>
                <li>Complete audit checklists online on any device</li>
                <li>Add photos to an audit</li>
                <li>
                  Actions created for non conformance and emailed to nominated
                  team member for action
                </li>
                <li>Job data pulled automatically from Traffio</li>
              </ul>
              <ActiondModuleHeading
                title="Client and Employee Surveys"
                icon="handshake"
              />{" "}
              <ul>
                <li>
                  Easily view your client and employee sentiment via the world
                  standard NPS® Score
                </li>
                <li>
                  Client and employee surveys automatically sent based on data
                  synchronised with Traffio
                </li>
                <li>
                  Actions created for negative feedback allowing you to rectify
                </li>
                <li>
                  Statistics and ratios automatically created allowing for trend
                  analysis
                </li>
                <li>
                  Track client and employee comments, both positive and negative
                </li>
              </ul>
              <ActiondModuleHeading title="Fleet Management" icon="truck" />{" "}
              <ul>
                <li>
                  Create and monitor fleet and equipment issues and faults
                </li>
                <li>
                  Record a fleet issue, add a severity rating and assign an
                  action to a user
                </li>
                <li>View a list of all fleet and equipment</li>
                <li>View a list and status of all actions</li>
                <li>Ability to close out actions or assign to another user</li>
                <li>Ability to sync fleet from an external system</li>
              </ul>
              <ActiondModuleHeading title="Task Management" icon="tasks" />{" "}
              <ul>
                <li>Create a task, add a priority and assign to a user</li>
                <li>View a list and status of all tasks</li>
                <li>Ability to close out tasks or assign to another user</li>
              </ul>
            </Col>
            <Col lg={5} className="mb-3">
              <img
                src="https://actiond.azureedge.net/public-assets/actiond-screenshots/v1.7/view-documents.png"
                alt="Actiond - View Documents"
                className="screenshot my-2"
              />
              <img
                src="https://actiond.azureedge.net/public-assets/actiond-screenshots/v1.7/incident-stats.png"
                alt="Actiond - Incident Management"
                className="screenshot my-2"
              />
              <img
                src="https://actiond.azureedge.net/public-assets/actiond-screenshots/v1.7/create-an-audit.png"
                alt="Actiond - Auditing"
                className="screenshot my-2"
              />
              <img
                src="https://actiond.azureedge.net/public-assets/actiond-screenshots/v1.7/view-client-feedback.png"
                alt="Actiond - Client and Employee Feedback"
                className="screenshot my-2"
              />
              <img
                src="https://actiond.azureedge.net/public-assets/actiond-screenshots/v1.7/view-fleet-items.png"
                alt="Actiond - Fleet Issues"
                className="screenshot my-2"
              />
            </Col>
          </Row>
          <p>
            <a
              href="https://actiond.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn learn color-1-bg color-2-bg-hv my-1"
            >
              Check It Out <i className="fas fa-angle-right"></i>
            </a>{" "}
            <a
              href="https://actiond.azureedge.net/public-assets/pdfs/actiond-brochure-v3.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="btn learn color-1-bg color-2-bg-hv my-1"
            >
              Download Brochure <i className="fas fa-angle-right"></i>
            </a>
          </p>
        </ContentSection>

        <ContentSection bgClass="gry">
          <h2>
            Productivity <span className="color-1">Tools</span>
          </h2>
          <div className="row">
            <div className="col-lg-7">
              <p>
                The team at Solvable have created a number of custom
                Productivity Tools that assist business with various automation
                and business productivity.
              </p>
              <p>
                These tools can be configured, based on your business
                requirements to undertake the following examples.
              </p>
              <ul className="dot-list">
                <li>
                  Streamlining enrolment processes, service delivery, and
                  feedback collection.
                </li>
                <li>
                  Designing workflows that will eliminate time-consuming steps
                  and measuring its successes.
                </li>
                <li>
                  Managing staff database, salary records, and analytical
                  reports on a single platform.
                </li>
                <li>
                  Refocus your resources and energies on core business functions
                  like sales and marketing.
                </li>
                <li>
                  Modernise the end-to-end processes such as production and
                  distribution methods.
                </li>
                <li>
                  Collaborate and increase productivity with tools that enable
                  you to communicate seamlessly and securely with your team.
                </li>
                <li>And more</li>
              </ul>
            </div>
            <div className="col-lg-5 mb-3">
              <img
                src="https://solvable.azureedge.net/website-assets/productivity-tool.jpg"
                alt="Coinvest Calculator"
                className="screenshot"
              />
              <p className="text-center image-title">
                CoInvest tool created for one of our clients
              </p>
            </div>
          </div>
        </ContentSection>
        <ContentSection>
          <h2>
            Our <span className="color-1">Partners</span>
          </h2>
          <p>
            We value the partners that support our business, both in advice and
            referrals. Some of those that have assisted with our products are
            listed below.
          </p>
          <div class="row d-flex align-items-center justify-content-center">
            <div class="col-6 col-md-3 text-center">
              <a
                href="https://www.strategically.com.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://solvable.azureedge.net/website-assets/strategically-yours-logo.png"
                  alt="Strategically Yours"
                />
              </a>
            </div>
            <div class="col-6 col-md-3 text-center">
              <a
                href="https://www.traffio.com.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://solvable.azureedge.net/website-assets/traffio-logo2.png"
                  alt="Traffio"
                />
              </a>
            </div>
            <div class="col-6 col-md-3 text-center">
              <a
                href="https://tmaa.asn.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://solvable.azureedge.net/website-assets/tmaa-logo-2.png"
                  alt="TMAA"
                />
              </a>
            </div>
            {/* <div class="col-6 col-md-3 text-center">
              <a
                href="https://imasolutions.com.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://solvable.azureedge.net/website-assets/imas-logo.png"
                  alt="IMAS"
                />
              </a>
            </div> */}
          </div>
        </ContentSection>
      </React.Fragment>
    ),
  },
  {
    pageName: "privacy-policy",

    content: (
      <React.Fragment>
        <PageTagging title="Solvable | Privacy Policy" description="" />
        <ContentSection>
          <h1>Privacy Policy</h1>
          <p>(Last Updated: 17.02.2020)</p>
          <h3>Acceptance of Terms</h3>
          <ul className="dot-list">
            <li>
              Solvable.com.au and actiond.io (“Website”) and Solvable Mobile
              Apps and Actiond (“Mobile App”) (collectively referred to as
              “Solvable”) are owned and operated by Solvable Pty Ltd ACN 630 328
              291 (“Owner”), and will be referred to as "We", "our" and "us" in
              this Privacy Policy.
            </li>
            <li>
              By accessing or using Solvable or using any application that
              integrates with or makes use of Solvable, you agree to be bound by
              terms of this Privacy Policy.
            </li>
            <li>
              The Privacy Policy relates to the collection and use of personal
              information you may supply to us through your use of Solvable.
            </li>
            <li>
              We reserve the right, at our discretion, to modify or remove
              portions of this Privacy Policy at any time. This Privacy Policy
              is in addition to any other terms and conditions applicable to
              Solvable. We do not make any representations about third party web
              sites that may be linked to Solvable.
            </li>
            <li>
              We recognise the importance of protecting the privacy of
              information collected about visitors to our web site, in
              particular information that is capable of identifying an
              individual ("Personal Information"). This Privacy Policy governs
              the manner in which your personal information, obtained through
              the web site, will be dealt with. This Privacy Policy should be
              reviewed periodically so that you are updated on any changes. We
              welcome your comments and feedback.
            </li>
          </ul>
          <h3>Personal Information</h3>
          <ul className="dot-list">
            <li>
              Personal Information about visitors to Solvable is collected only
              when knowingly and voluntarily submitted. For example, we may need
              to collect such information to provide you with further services
              or to answer or forward any requests or enquiries.
            </li>
            <li>
              It is our intention that this Privacy Policy will protect your
              Personal Information from being dealt with in any way that is
              inconsistent with applicable privacy laws in Australia.
            </li>
          </ul>
          <h3>Use of Information</h3>
          <ul className="dot-list">
            <li>
              Personal Information that visitors submit to Solvable is used only
              for the purpose for which it is submitted or for such other
              secondary purposes that are related to the primary purpose, unless
              we disclose other uses in this Privacy Policy or at the time of
              collection.
            </li>
            <li>
              Copies of correspondence sent from Solvable, that may contain
              Personal Information, are stored as archives for record-keeping
              and back-up purposes only.
            </li>
            <li>
              We may freely share any information collected with our related
              bodies corporate (as defined in the Corporations Act 2001 (Cth)).
            </li>
            <li>
              Unless otherwise agreed in a contract with you, we may appoint
              third party service providers to assist us in providing services
              to you. We may disclose your information to these providers as
              necessary to provide services to you. These service providers may
              be located overseas in jurisdictions.
            </li>
          </ul>
          <h3>Collection of Personal Information of all users</h3>
          <ul className="dot-list">
            <li>
              Whenever users visit the Website, our servers automatically record
              information for statistical purposes about your usage of the
              Website such as;
              <ul>
                <li>the type of browser used</li>
                <li>the referring URL</li>
                <li>the IP address</li>
                <li>the number and type of pages viewed</li>
                <li>the date and time of visits; and</li>
                <li>the exit URL.</li>
              </ul>
              <li>
                This information we collect about you is not Personal
                Information and, in any event, remains anonymous. We do not link
                the anonymous information to any other Personal Information
                unless you have either registered with Solvable or logged in at
                the time of use. Accordingly, if you have not identified
                yourself on the Website, such as by registering or logging in,
                we will not link a record of your usage of our Website to you
                personally.
              </li>
            </li>
          </ul>
          <h3>Collection of Personal Information of Registered Users</h3>
          <ul className="dot-list">
            <li>
              Upon your registration with Solvable we collect information about
              you in order to provide you with access to a Platform or App. We
              collect non-identifying information as well as Personal
              Information from you directly when you first register and also
              from time to time thereafter if you provide us with additional
              information.
            </li>
            <li>
              Personal Information which may be collected by Solvable includes,
              without limitation;
            </li>
            <ul>
              <li>your full name</li>
              <li>your email address</li>
              <li>your birth date</li>
              <li>your country of residence</li>
            </ul>
          </ul>
          <h3>Information Collection Practices</h3>
          <ul className="dot-list">
            <li>
              We may use personal information you provide, such as contact
              details, for;
            </li>
            <ul>
              <li>providing access to Solvable and other Solvable services</li>
              <li>promoting and improving our products and services</li>
              <li>
                promoting and advertising third party affiliate services to you
              </li>
              <li>billing and account management; and</li>
            </ul>
            <li>
              We do not sell any personal information to any third party.{" "}
            </li>
            <li>
              Where you link to a third party site from Solvable, we may provide
              session information to the third party, and may receive
              commissions from affiliates if you proceed to purchase products or
              services from that third party.
            </li>
            <li>
              We may use and disclose your personal information to avoid an
              imminent threat to a person's life or to public safety, or for
              reasons related to law enforcement or internal investigations into
              unlawful activities.
            </li>
            <li>
              We may use and disclose your personal information to identify,
              contact or bring legal action against any entity we suspect to be
              violating our terms of use, privacy or any other policy, or who is
              causing injury or interference with our rights, property,
              customers, or any third party who could reasonably be harmed by
              their actions.
            </li>
          </ul>
          <h3>Credit Card Details</h3>
          <ul className="dot-list">
            <li>
              Credit Card details are securely stored with our payment gateway
              provider for the processing of payment and will be deleted upon
              cancellation of your subscription.
            </li>
          </ul>
          <h3>Disclosure</h3>
          <ul className="dot-list">
            <li>
              Apart from where you have consented or disclosure is necessary to
              achieve the purpose for which it was submitted, personal
              information may be disclosed in special situations where we have
              reason to believe that doing so is necessary to identify, contact
              or bring legal action against anyone damaging, injuring, or
              interfering (intentionally or unintentionally) with our rights or
              property, users, or anyone else who could be harmed by such
              activities. Also, we may disclose personal information when we
              believe in good faith that the law requires disclosure.
            </li>
            <li>
              We may engage third parties to provide you with goods or services
              on our behalf. In that circumstance, we may disclose your personal
              information to those third parties in order to meet your request
              for goods or services.
            </li>
            <li>
              For the avoidance of any doubt, we will not disclose any Personal
              Information about you without your consent unless we believe it is
              necessary;
            </li>
            <ul>
              <li>to comply with the law</li>
              <li>to provide you with a service which you have requested</li>
              <li>to implement our terms of service</li>
              <li>
                to protect our rights or property, or the rights or property of
                any of our other users or any member of the public; or
              </li>
              <li>
                to lessen a serious threat to a person's health or safety.
              </li>
            </ul>
          </ul>
          <h3>Security</h3>
          <ul className="dot-list">
            <li>
              We strive to ensure the security, integrity and privacy of
              personal information submitted to our sites, and we review and
              update our security measures in light of current technologies.
            </li>
            <li>
              Unfortunately, no data transmission over the Internet can be
              guaranteed to be totally secure. However, we will endeavour to
              take all reasonable steps to protect the personal information you
              may transmit to us or from our online products and services. Once
              we do receive your transmission, we will also make our best
              efforts to ensure its security on our systems.
            </li>
            <li>
              In addition, our employees and the contractors who provide
              services related to our information systems are obliged to respect
              the confidentiality of any personal information held by us.
              However, we will not be held responsible for events arising from
              unauthorised access to your personal information.
            </li>
            <li>
              You can also play an important role in keeping your Personal
              Information secure by maintaining the confidentiality of any
              password, PINs and accounts used on the Solvable. Please notify us
              immediately if there is any unauthorised use of your account by
              any other person or any other breach of security.
            </li>
          </ul>
          <h3>Collecting Information from Users</h3>
          <ul className="dot-list">
            <li>
              IP Addresses: Our web servers gather your IP address to assist
              with the diagnosis of problems or support issues with our
              services. Again, information is gathered in aggregate only and
              cannot be traced to an individual user.
            </li>
            <li>Cookies and Applets;</li>
            <ul>
              <li>
                Cookies are pieces of information that a website transfers to
                your computer's hard disk for record-keeping purposes. Most web
                browsers are set to accept cookies. If you do not wish to
                receive any cookies, you may set your browser to refuse cookies.
                In some instances, this may mean you will not be able to take
                full advantage of parts of the Website.
              </li>
              <li>
                We use cookies to provide you with a better experience. These
                cookies allow us to increase your security by storing your
                session ID and are a way of monitoring single user access.
              </li>
            </ul>
            <li>
              This aggregate, non-personal information is collated and provided
              to us to assist in analysing the usage of the site.
            </li>
          </ul>
          <h3>Access to Information</h3>
          <ul className="dot-list">
            <li>
              We will endeavour to take all reasonable steps to keep secure any
              information which we hold about you, and to keep this information
              accurate and up to date. If, at any time, you discover that
              information held about you is incorrect, you may contact us to
              have the information corrected.
            </li>
            <li>
              In addition, our employees and the contractors who provide
              services related to our information systems are obliged to respect
              the confidentiality of any personal information held by us.
            </li>
          </ul>
          <h3>Links to other sites</h3>
          <ul className="dot-list">
            <li>
              We may provide links to web sites outside of Solvable, as well as
              to third party websites. These linked sites are not under our
              control, and we cannot accept responsibility for the conduct of
              companies linked to our Website.
            </li>
            <li>
              Before disclosing your personal information on any other website,
              we advise you to examine the terms and conditions of using that
              web site and its privacy statement.
            </li>
          </ul>
          <h3>Trans-Border Data Exchange</h3>
          <ul className="dot-list">
            <li>
              We may transfer (and you are deemed to consent to us transferring)
              your Personal Information outside of Australia;
            </li>
            <ul>
              <li>
                where we reasonably believe that the person who will receive
                your Personal Information is subject to effective data
                protection obligations
              </li>
              <li>
                where the transfer is necessary for the performance of any
                contract between you and us or for the implementation of
                pre-contractual measures
              </li>
              <li>
                where the transfer is for your benefit and it is impractical for
                us to obtain your consent, but if it were practical you would be
                likely to consent; and
              </li>
              <li>
                as required or authorised by or under law, and where you
                subsequently consent from time to time.
              </li>
            </ul>
          </ul>
          <h3>"Spam" (Promotional Email)</h3>
          <ul className="dot-list">
            <li>
              We will not send you "spam" (advertising information) without
              first asking you whether you wish to have promotional information
              sent to you.
            </li>
            <li>
              If you are receiving promotional information from us and do not
              wish to receive this information any longer, you may remove your
              name from our list by contacting us and asking to be removed from
              our mailing list. Please allow five days for this request to be
              processed.
            </li>
          </ul>
          <h3>Problems or questions</h3>
          <ul className="dot-list">
            <li>
              If we become aware of any ongoing concerns or problems with
              Solvable, we will take these issues seriously and work to address
              these concerns. If you have any further queries relating to our
              Privacy Policy, or you have a problem or complaint, please contact
              us.{" "}
            </li>
            <li>
              For more information about privacy issues in Australia and
              protecting your privacy, visit the Australian Federal Privacy
              Commissioner's website:{" "}
              <a
                href="http://www.privacy.gov.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.privacy.gov.au
              </a>
            </li>
          </ul>
        </ContentSection>
      </React.Fragment>
    ),
  },
  ...publicServicesPages,
  {
    pageName: "not-found",

    content: (
      <React.Fragment>
        <PageTagging title="Page Not Found" description="" />
        <ContentSection>
          <h1>Page Not Found</h1>
        </ContentSection>
      </React.Fragment>
    ),
  },
];
