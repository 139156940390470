import React from "react";
import "./contact-details.scss";

const ContactDetails = () => {
  return (
    <div className="contact-details">
      <h4>Postal Address</h4>
      <p>PO Box 740, Toowong DC, QLD&nbsp;4066,&nbsp;Australia.</p>
      <h4>Phone</h4>
      <p>
        <a href="tel:1300732433">1300&nbsp;732&nbsp;433</a>
      </p>
      <h4>E-mail</h4>
      <p>
        <a
          href="mailto:letssolve@solvable.com.au"
          style={{ textTransform: "none" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          letssolve@solvable.com.au
        </a>
      </p>
      <div className="contact-icons">
        <a
          href="https://www.linkedin.com/company/solvable-pty-ltd/about"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-linkedin"></i>
        </a>
      </div>
    </div>
  );
};

export default ContactDetails;
