import React from "react";
import ContentSection from "../../components/common/content-section";
import OurHighlights from "./components/our-highlights";
import ContactUsForm from "../../components/contact-us-form";
import PageTagging from "components/common/page-tagging/page-tagging";

const CapabilityStatement = () => {
  const highlights = [
    {
      title: "Real-Time System Integration",
      details: "Link between disparate systems to improve efficiency",
    },
    {
      title: "Salesforce Integration Tools",
      details: "Single source of truth",
    },
    {
      title: "Paperless Tablet solution",
      details: "Safety documentation and timesheeting",
    },
    {
      title: "Incident Management System",
      details: "Record incidents, actions and track investigation outcomes",
    },
    {
      title: "Document Management System",
      details: "Compliance with ISO 9001",
    },
    {
      title: "Quality & Safety Auditing System",
      details: "Compliance with ISO 9001 and AS4801",
    },
    {
      title: "Spreadsheet Helper Tools",
      details: "Automating manual processes, saving 1,000+ hrs per annum",
    },
    { title: "Automated NPS and ENPS", details: "Including automated actions" },
    {
      title: "State Electoral Party Budget & Purchasing System",
      details: "Improve workflows to create cost control",
    },
    {
      title: "University Experimental Data Processing & Compilation",
      details: "Automation of data processing, saving 3 months per annum",
    },
    {
      title: "Mobile Phone Applications",
      details: "Job notification and staff notices",
    },
    {
      title: "Job Booking / Scheduling & Resource Management System",
      details: "People and equipment",
    },
    {
      title: "Award Interpretation & Complex Invoicing System",
      details: "Automated Payroll & Invoicing for $130M+ business",
    },
    {
      title: "Calculate Radius Based Travel Payroll Conditions",
      details: "Integrate Google Maps APIs",
    },
    {
      title: "KPI Tracking System",
      details: "Track business KPIs using data from multiple systems",
    },
    {
      title: "Task Management System",
      details: "Allocation of tasks for both onshore and offshore staff",
    },
    {
      title: "Online Training System for RTOs",
      details:
        "Online training platform tracking purchases, enrolments certificates and Avetmiss reporting",
    },
    {
      title: "Purchase Order Tracking System",
      details: "Automated approval chain",
    },
    {
      title: "Electronic Notice Board",
      details: "Provide messages to your employees at any location",
    },
    {
      title: "Staff Information Portal",
      details: "Access to company news, forms and safety alerts",
    },
    {
      title: "Ticketing System",
      details: "Track employee queries, suggestions and concerns",
    },
    {
      title: "eLearning system",
      details: "Allocate and track employee learning",
    },
    {
      title: "Sports Statistics App",
      details:
        "Intuitive data capturing, advanced reporting & analysis. Over 20,000 active users and over 1 million app logins",
    },
    {
      title: "Electronic Payment Gateway Integrations",
      details: "Integration with various payment gateway providers",
    },
    {
      title: "Point of Sale Interface & Integration",
      details: "Web based interface for backend POS system",
    },
    {
      title: "Online Shopping Management System",
      details: "Automated eBay & shopping cart listings",
    },
    {
      title: "Mobile App with Bluetooth connectivity",
      details: "Manage an external device via Bluetooth",
    },
    {
      title: "Tablet based Logistics solution",
      details: "Custom solution to enable paperless logistics",
    },
  ];

  return (
    <React.Fragment>
      <ContentSection>
        <PageTagging
          title="Our Capabilities | Custom Software Development | Solvable"
          description="We provide business efficiency and client value through creating cost effective, innovative solutions utilising cutting edge technologies."
        />
        <h1>
          Capability <span className="color-1">Statement</span>
        </h1>
        <p className="fs-larger fw-500">
          The founders of Solvable have been in business management and
          technology leadership roles for over 20 years and have extensive
          experience in creating effective business efficiency through
          technology.
        </p>
        <p>
          We provide business efficiency and client value through creating cost
          effective, innovative solutions utilising cutting edge technologies,
          designed with a philosophy that &ldquo;
          <span className="fw-500 color-1">
            Anything is Possible, Everything is Solvable
          </span>
          &rdquo;.
        </p>
        <p>
          With an idea in mind, simply discuss with us your vision, goals, and
          budget. No clue where to start? Let us know what challenges you have
          and how you want your business to grow or improve. Your advice is
          important to us and will be a vital part of the solution.
        </p>
        <p>
          We will listen and learn about your business goals and via a
          partnering approach, we will build a solution that will exceed your
          expectations.
        </p>
      </ContentSection>
      <ContentSection bgClass="gry">
        <h2 className="upper text-center mb-4">
          Our <span className="color-1">Capabilities</span>
        </h2>
        <div className="row">
          <div className="col-sm-6 col-12 text-center">
            <h4>Technology Stack</h4>
            <ul className="text-list">
              <li>C# / Microsoft .NET (.NET Core &amp; .NET Framework)</li>
              <li>Web API, MVC, Apps &amp; System Services</li>
              <li>APIs (RESTful &amp; SOAP)</li>
              <li>Python, Node.js, Java &amp; more</li>
              <li>Big Data &amp; Analytics</li>
              <li>Microservices</li>
              <li>IoT (Internet of Things)</li>
              <li>
                HTML5 / Single Page Apps (React, AngularJS, Angular &amp; more)
              </li>
              <li>Mobile Apps (iOS, Android &amp; Hybrid)</li>
              <li>Databases (SQL / NoSQL)</li>
              <li>QA &amp; Automated Testing</li>
            </ul>
          </div>
          <div className="col-sm-6 col-12 text-center">
            <h4>How We Add Value</h4>
            <ul className="text-list">
              <li>Solution Architecture</li>
              <li>Solution Design</li>
              <li>Risk Management</li>
              <li>Change Management</li>
              <li>Solution Roadmap</li>
              <li>Agile / Lean Methodologies</li>
              <li>Solution Consulting</li>
              <li>ROI Validation</li>
              <li>Executive Proposals</li>
            </ul>
          </div>
        </div>
      </ContentSection>
      <ContentSection>
        <h2 className="upper text-center mb-4">
          Our <span className="color-1">Highlights</span>
        </h2>
        <OurHighlights highlights={highlights} />
      </ContentSection>
      <section className="contact p100" id="contact">
        <div className="overlay gradient"></div>
        <ContactUsForm />
      </section>
    </React.Fragment>
  );
};

export default CapabilityStatement;
