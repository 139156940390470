export const navLinks = [
  {
    title: "Home",
    href: "/",
    subLinks: [],
  },
  {
    title: "About",
    href: "/about/",
    subLinks: [],
  },
  {
    title: "Capability",
    href: "/capability-statement/",
    subLinks: [],
  },
  {
    title: "Recent Projects",
    href: "/recent-projects/",
    subLinks: [],
  },
  {
    title: "Services",
    href: "/what-we-do/",
    subLinks: [
      {
        title: "Software Scope, Design, Build",
        href: "/project-software-scope-design-build/",
        subLinks: [],
      },
      {
        title: "Systems Integration & Middleware",
        href: "/systems-integration-and-middleware/",
        subLinks: [],
      },
      {
        title: "Productivity & Automation Tools",
        href: "/productivity-and-automation-tools/",
        subLinks: [],
      },
    ],
  },
  {
    title: "Products",
    href: "/products/",
    subLinks: [],
  },
  {
    title: "Contact",
    href: "/get-in-touch/",
    subLinks: [],
  },
];
