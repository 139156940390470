import React from "react";
import "./actiond-module-heading.scss";

const ActiondModuleHeading = ({ title, subTitle, icon }) => {
  return (
    <div className="actiond-module-heading d-flex pb-3">
      <div className="actiond-module-icon">
        <i className={`fas fa-${icon}`}></i>
      </div>
      <div className="flex-grow-1 align-self-center pl-2">
        <h2>{title}</h2>
        {subTitle && <h3>{subTitle}</h3>}
      </div>
    </div>
  );
};

export default ActiondModuleHeading;
