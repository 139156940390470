import React, { Component } from "react";
import { withRouter } from "react-router";
import PageTagging from "components/common/page-tagging/page-tagging";
import * as PublicContent from "../../constants/public-content";
import ContactUsForm from "../../components/contact-us-form";

class ContentPage extends Component {
  getPageName = (pathName) => {
    return pathName
      .replace(new RegExp("^[/]+"), "")
      .replace(new RegExp("[/]+$"), "")
      .toLowerCase();
  };

  getContentPage = (pageName) => {
    let page = PublicContent.publicPages.filter(
      (page) => page.pageName === pageName
    );

    if (page.length === 0)
      page = PublicContent.publicPages.filter(
        (page) => page.pageName === "not-found"
      );

    return page[0];
  };

  render() {
    const { location } = this.props;
    const pageName = this.getPageName(location.pathname);
    const page = this.getContentPage(pageName);

    return (
      <React.Fragment>
        <PageTagging title={page.title} description={page.description} />
        {page.content}
        <section className="contact p100" id="contact">
          <div className="overlay gradient"></div>
          <ContactUsForm />
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(ContentPage);
