import React from "react";
import WhyColumn from "./components/why-column";
import "./why-solvable.scss";

const WhySolvable = () => {
  return (
    <section id="why" className="why p100 gry">
      <div className="fluid-container fixed-width-fluid">
        <div className="title">
          <div className="slvicon slvicon-why"></div>
          <h2 className="upper text-center">
            Why Use <span className="color-1">Solvable</span>
          </h2>
          <p className="fs-larger fw-500">
            The{" "}
            <a href="/about/#our-founders" className="link">
              founders of Solvable
            </a>{" "}
            have been in business management and technology leadership roles for
            over 20 years and have extensive experience in creating effective
            business efficiency through technology.
          </p>
          <p>
            We provide business efficiency and client value through creating
            cost effective, innovative solutions utilising cutting edge
            technologies, designed with a philosophy that &ldquo;
            <span className="fw-500 color-1">
              Anything is Possible, Everything is Solvable
            </span>
            &rdquo;.
          </p>
          <br />
        </div>
        <div className="row">
          <WhyColumn
            title="We Speak Your Language"
            line1="We think business, we speak your language, we listen and want you to succeed."
            icon="speak"
          />
          <WhyColumn
            title="We&rsquo;ve Seen a Lot"
            line1="Multiple countries, multiple industries, lots of experiences. Broad backgrounds."
            icon="seen"
          />
          <WhyColumn
            title="We Think Outside the Box"
            line1="Our philosophy is Anything is Possible, Everything is Solvable."
            icon="think"
          />
          <WhyColumn
            title="We Help Minimise Risk"
            line1="We employ a partnership approach; we want you to succeed and minimise risk."
            icon="risk"
          />
          <WhyColumn
            title="We&rsquo;re Responsive"
            line1="We get it done! Through our innovative team approach, we deliver quality solutions within a minimum timeframe and budget."
            icon="responsive"
          />
          <WhyColumn
            title="We Do More than Just IT"
            line1="We&rsquo;re not just IT people, we&rsquo;re business analysts with extensive operational and management backgrounds."
            icon="more"
          />
        </div>
      </div>
    </section>
  );
};

export default WhySolvable;
