import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Form, Row, Col } from "reactstrap";
import ContentSection from "../../components/common/content-section";
import ButtonFx from "../../components/common/button-fx";
import ContactDetails from "../../components/contact-details";
import * as Constants from "../../constants";
import "./contact-us-form.scss";

const ContactUsForm = ({ addNotification }) => {
  //addNotification("Please enter a valid email address.", "error");

  const [isSubmitting, setState] = useState();

  const { register, handleSubmit, errors, reset } = useForm();

  const onSubmit = (data) => {
    if (
      !data.email ||
      data.email === "" ||
      !Constants.emailRegex.test(data.email)
    ) {
      console.log("Boo");
      addNotification("Please enter a valid email address.", "error");

      return;
    }

    setState(true);

    axios
      .post("https://functions.solved.net.au/api/solvable/contact", {
        name: data.name,
        phone: data.phone,
        email: data.email,
        message: data.message,
      })
      .then(function (response) {
        reset();
        setState(false);
        if (response.data === true)
          addNotification(
            "Thank you, your email has been sent. A member of the Solvable team will be in touch with you shortly."
          );
        else addNotification("Oops something went wrong. Please try again.");
      })
      .catch(function (error) {
        setState(false);
        addNotification("Oops something went wrong. Please try again.", error);
      });
  };

  const getClassName = (name) => {
    return `${name} form-control${errors[name] ? " invalid-field" : ""}`;
  };

  return (
    <React.Fragment>
      <ContentSection bgClass="contact-form-wrap">
        <h1 className="upper text-center">Get in Touch</h1>
        <h5 className="text-center mt-4">
          Solvable is a team that loves a challenge and is passionate about
          solving complex problems.
        </h5>
        <h5 className="text-center mt-3 mb-4">
          Let&rsquo;s work together to solve your business needs and make you
          more efficient and effective.
        </h5>
        <Row>
          <Col md={7} className="p-0 d-flex flex-column">
            <Row className="mt-3 mx-3 contact-form-bg">
              <Col className="p-3 contact-form">
                <Form
                  noValidate
                  className="flex-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row form>
                    <Col md={6} className="pb-2 name-col">
                      <input
                        name="name"
                        className={getClassName("name")}
                        placeholder="Name *"
                        ref={register({ required: true })}
                      />
                    </Col>
                    <Col md={6} className="pb-2 phone-col">
                      <input
                        name="phone"
                        className={getClassName("phone")}
                        placeholder="Phone Number *"
                        ref={register({ required: true })}
                      />
                    </Col>
                    <Col md={12} className="pb-2 email-col">
                      <input
                        name="email"
                        className={getClassName("email")}
                        type="email"
                        placeholder="Email Address *"
                        ref={register({ required: true })}
                      />
                    </Col>
                    <Col md={12} className="pb-2 message-col">
                      <textarea
                        name="message"
                        className={getClassName("message")}
                        placeholder="Your Message *"
                        ref={register({ required: true })}
                      />
                    </Col>
                    <Col md={12} className="pb-2 btn-col">
                      <ButtonFx
                        type="submit"
                        color="btn learn color-1-bg color-3-bg-hv"
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                      >
                        Send
                      </ButtonFx>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col md={5} className="p-0 d-flex flex-column">
            <Row className="mt-3 mx-3 contact-form-bg">
              <Col className="p-3 contact-details">
                <ContactDetails />
              </Col>
            </Row>
          </Col>
        </Row>
      </ContentSection>
    </React.Fragment>
  );
};

export default ContactUsForm;
