/**
 * ButtonFx
 * - Progress spinner feature
 * rcnet @Solvable 5.5.2019
 *
 * props exposed:
 *   - onClick = event onClick
 *   - isLoading = true or false (spinner hide/show)
 *   - name = button name
 *   - targetName = name of button to trigger loading spinner
 *   - color  = refer to reacstrap button colors
 */
import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { css } from "@emotion/core";
import { BeatLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 5px;
`;

const ButtonFx = (props) => {
  const loadSpinner =
    (props.targetName === props.name || props.targetName === "") &&
    props.isLoading;

  const {
    id,
    type,
    color,
    name,
    round,
    icon,
    isLoading,
    disabled,
    onClick,
    block,
    className,
  } = props;

  return (
    <Button
      //{...props}
      id={id}
      type={type}
      block={block}
      color={color}
      name={name}
      onClick={onClick}
      //size={size}
      round={round}
      icon={icon}
      className={className}
      disabled={isLoading || disabled}
    >
      {loadSpinner ? (
        <BeatLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={"#fff"}
          loading={true}
        />
      ) : (
        <span>{props.children}</span>
      )}
    </Button>
  );
};

ButtonFx.propTypes = {
  color: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  targetName: PropTypes.string,
  size: PropTypes.string,
  round: PropTypes.string,
  icon: PropTypes.string,
};

ButtonFx.defaultProps = {
  color: "actiond",
  name: "fxButton",
  targetName: "",
  isLoading: false,
  size: "",
  round: "true",
  icon: "false",
};

export default ButtonFx;
