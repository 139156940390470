import React from "react";

const WhyColumn = ({ title, line1, icon }) => {
  return (
    <div className="col-lg-4 col-sm-6 col-12 mt-4">
      <div className="box text-center">
        <div className={`slvicon slvicon-${icon}`}></div>
        <h3>{title}</h3>
        <p>{line1}</p>
      </div>
    </div>
  );
};

export default WhyColumn;
