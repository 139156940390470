import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <footer className="text-center">
      <div className="container">
        <p>
          Copyright &copy; {new Date().getFullYear()} Solvable Pty Ltd{" | "}
          <a href="/privacy-policy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
